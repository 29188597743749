<template>
  <div class="my-3">
    <div class="row">
      <!-- ámbito -->
      <div class="col-md-4 col-lg-3">
        <label for="state" class="form-label">Ámbito:</label>
        <select
          id="state"
          class="form-select"
          @change="addParamToURL('ambito_id', this.selectedAmbito)"
          v-model="selectedAmbito"
        >
          <option value="all">Todos</option>
          <option v-for="ambito in ambitos" :value="ambito.id" :key="ambito.id">
            {{ ambito.name }}
          </option>
        </select>
      </div>
      <!-- Ordenamiento -->
      <div class="col-md-4 col-lg-3">
        <label for="ordenamiento" class="form-label">Ordenamiento:</label>
        <select
          id="ordenamiento"
          class="form-select"
          @change="addParamToURL('ordenamiento_id', this.selectedOrdenamiento)"
          v-model="selectedOrdenamiento"
        >
          <option value="all">Todos</option>
          <option
            v-for="ordenamiento in ordenamientos"
            :value="ordenamiento.id"
            :key="ordenamiento.id"
          >
            {{ ordenamiento.name }}
          </option>
        </select>
      </div>
      <!-- Aspecto -->
      <div class="col-md-4 col-lg-3">
        <label for="aspecto" class="form-label">Aspecto:</label>
        <select
          id="aspecto"
          class="form-select"
          @change="addParamToURL('aspecto_id', this.selectedAspecto)"
          v-model="selectedAspecto"
        >
          <option value="all">Todos</option>
          <option
            v-for="aspecto in aspectos"
            :value="aspecto.id"
            :key="aspecto.id"
          >
            {{ aspecto.name }}
          </option>
        </select>
      </div>
      <!-- Autoridad reguladora -->
      <div class="col-md-4 col-lg-3">
        <label for="autoridad" class="form-label">Autoridad reguladora:</label>
        <select
          id="autoridad"
          class="form-select"
          @change="
            addParamToURL('autoridad_reguladora_id', this.selectedAutoridad)
          "
          v-model="selectedAutoridad"
        >
          <option value="all">Todos</option>
          <option
            v-for="autoridad in autoridades"
            :value="autoridad.id"
            :key="autoridad.id"
          >
            {{ autoridad.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import { all as allAutoridades } from "@/services/autoridades";
import { all as allAspectos } from "@/services/aspectos";
import { all as allOrdenamientos } from "@/services/ordenamientos";
import { all as allAmbitos } from "@/services/ambitos";
import { addParamToURL } from "@/shared/methods.vue";

export default {
  data() {
    return {
      ordenamientos: [],
      aspectos: [],
      autoridades: [],
      ambitos: [],
      selectedAmbito: this.$route.query.ambito_id || "all",
      selectedState: this.$route.query.state_id || "all",
      selectedOrdenamiento: this.$route.query.ordenamiento_id || "all",
      selectedAspecto: this.$route.query.aspecto_id || "all",
      selectedAutoridad: this.$route.query.autoridad_reguladora_id || "all",
    };
  },
  mounted() {
    this.getAutoridades();
    this.getAspectos();
    this.getOrdenamientos();
    this.getAmbitos();
  },
  methods: {
    addParamToURL(filterName, filterValue) {
      addParamToURL(filterName, filterValue, this).then(() => {
        this.$emit("search");
      });
    },
    getAutoridades() {
      allAutoridades()
        .then((response) => {
          this.autoridades = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las autoridades reguladoras");
        });
    },
    getAspectos() {
      allAspectos()
        .then((response) => {
          this.aspectos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los aspectos");
        });
    },
    getOrdenamientos() {
      allOrdenamientos()
        .then((response) => {
          this.ordenamientos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los ordenamientos");
        });
    },
    getAmbitos() {
      allAmbitos()
        .then((response) => {
          this.ambitos = response.data;
          this.ambitoFederalId = this.ambitos.find(
            (ambito) => ambito.name === "Federal"
          ).id;
        })
        .catch(() => {
          alert("No pudimos cargar los ambitos");
        });
    },
  },
};
</script>
