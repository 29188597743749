<template>
  <div class="container">
    <div
      v-if="
        !loadingUserData &&
        authUser.marcos_legales_especificos_count >
          userMarcosLegalesEspecificos.length
      "
      class="alert alert-warning"
      role="alert"
    >
      Has excedido el límite de marcos legales, por lo cuál solo tendrás
      disponibles 100.
    </div>
    <filters @search="getMarcosLegalesGenerales()" />
    <c-card title="Marcos legales">
      <template v-slot:under-title>
        <p class="text-info">
          Requisitos legales: {{ authUser.marcos_legales_especificos_count }}/
          <show-paid-or-unpaid-data :paidInfo="true"
            >Ilimitadas</show-paid-or-unpaid-data
          >
          <show-paid-or-unpaid-data :paidInfo="false"
            >100</show-paid-or-unpaid-data
          >
        </p>
      </template>
      <template v-slot:header-right>
        <div>
          <div class="input-group mb-3 header-search">
            <input
              v-model="searchValue"
              type="text"
              class="form-control"
              @keyup="searchByMLEName"
            />
            <span class="input-group-text">
              <i class="fa-solid fa-magnifying-glass"></i>
            </span>
          </div>
        </div>
      </template>
      <div v-if="loadingMLES" class="d-flex justify-content-center">
        <div class="spinner-border my-5 mx-auto" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <table v-else class="table table-striped">
        <thead>
          <tr>
            <th>Marco legal general</th>
            <th>Descripción</th>
            <th>Ámbito</th>
            <th>Autoridad reguladora</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="MLG in marcosLegalesGenerales" :key="MLG.id">
            <td>{{ MLG.name }}</td>
            <td>{{ MLG.description }}</td>
            <td>
              {{ MLG?.versions[0]?.marcos_legales_especificos[0]?.ambito }}
            </td>
            <td>
              {{
                MLG?.versions[0]?.marcos_legales_especificos[0]
                  ?.autoridad_reguladora
              }}
            </td>
            <td class="text-nowrap">
              <button
                @click="
                  MLG.added_to_user
                    ? removeFromMatrizLegal(MLG.id)
                    : addToMatrizLegal(MLG.id)
                "
                class="text-nowrap btn"
                :class="MLG.added_to_user ? 'btn-secondary' : 'c-btn-primary'"
                :title="
                  MLG.added_to_user
                    ? 'Remover de mi matriz legal'
                    : 'Agregar a mi matriz legal'
                "
              >
                <span v-if="MLG.added_to_user">
                  <i class="fa-solid fa-minus"></i>
                </span>
                <span v-else>
                  <i class="fa-solid fa-plus"></i>
                </span>
              </button>
              <a
                v-if="MLG.document"
                target="_blank"
                class="btn btn-secondary ms-2"
                title="Ver marco legal general"
                :href="MLG.document"
              >
                <span>
                  <i class="fa-solid fa-eye"></i>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import { filterWithRelations } from "@/services/marcos_legales_generales";
import {
  addMarcoLegalGeneral,
  removeMarcoLegalGeneral,
  marcosLegalesEspecificos as userMarcosLegalesEspecificos,
  // DBPlan,
} from "@/services/users";
import { showNotification } from "@/services/notification";
import CCard from "@/components/CCard.vue";
import Filters from "@/components/MatrizLegal/Filters.vue";
import { addParamToURL } from "@/shared/methods.vue";
import showPaidOrUnpaidData from "@/components/showPaidOrUnpaidData";
import { user, authUser, setAuthUser } from "@/services/auth";

export default {
  components: {
    CCard,
    Filters,
    showPaidOrUnpaidData,
  },
  data() {
    return {
      loadingUserData: true,
      authUser: authUser(),
      userMarcosLegalesEspecificos: [],
      marcosLegalesGenerales: [],
      loadingMLES: true,
      searchValue: "",
      timer: null,
      mode: null,
      // dbPlan: {},
    };
  },
  mounted() {
    this.mode = this.$route.query.mode;
    delete this.$route.query.mode;

    this.getMarcosLegalesGenerales();
    // this.getDBPlan();
  },
  methods: {
    updateUserInfo() {
      user().then((response) => {
        setAuthUser(response.data);
        this.authUser = authUser();
        this.loadingUserData = false;
      });
    },
    getMarcosLegalesGenerales() {
      const queryParams = this.$route.query;
      filterWithRelations(queryParams)
        .then((response) => {
          this.marcosLegalesGenerales = response.data;
          this.loadingMLES = false;
          this.getUserMarcosLegalesEspecificos();
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error cargando la información. " + error
          );
        });
    },

    addToMatrizLegal(id) {
      addMarcoLegalGeneral(id)
        .then(() => {
          showNotification(
            200,
            "success",
            "Hemos agregado el registro a tu matriz legal"
          );
          this.getUserMarcosLegalesEspecificos();
        })
        .catch((error) => {
          let errorMessage = "Tuvimos un error para asignarte el registro";
          if (error.response.status === 400) {
            errorMessage =
              "Alcanzaste el límite de requisitos legales, actualiza tu plan para acceder a más requisitos legales";
          }
          showNotification(error.status, "danger", `${errorMessage} ${error}`);
        });
    },

    removeFromMatrizLegal(id) {
      const remove = confirm(
        "Al eliminar el marco legal se eliminarán los datos que hayas guardado para el status así como las acciones correctivas, ¿deseas continuar de cualquier forma?"
      );
      if (!remove) {
        return;
      }
      removeMarcoLegalGeneral(id)
        .then(() => {
          showNotification(
            200,
            "success",
            "Hemos eliminado el registro a tu matriz legal"
          );
          this.getUserMarcosLegalesEspecificos();
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error para eliminar registro. " + error
          );
        });
    },

    getUserMarcosLegalesEspecificos() {
      this.loadingUserData = true;
      userMarcosLegalesEspecificos()
        .then((response) => {
          this.userMarcosLegalesEspecificos = response.data;
          this.matchRecords();
          this.updateUserInfo();
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error obteniendo tu matriz legal"
          );
        });
    },

    matchRecords() {
      this.marcosLegalesGenerales.forEach((MLG) => {
        MLG.added_to_user = false;
        this.userMarcosLegalesEspecificos.forEach((userMLE) => {
          MLG.id === userMLE.marco_legal_general_version.marco_legal_general.id
            ? (MLG.added_to_user = true)
            : "";
        });
      });
    },

    searchByMLEName() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        addParamToURL("name", this.searchValue, this).then(() =>
          this.getMarcosLegalesGenerales()
        );
      }, 800);
    },

    /*getDBPlan() {
      DBPlan()
        .then((response) => (this.dbPlan = response.data))
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "Tuvimos un error obteniendo tu suscripción"
          );
        });
    },*/
  },
};
</script>
